.border-none:before {
  display: none;
}
.border-none:after {
  display: none;
}
.flex-fix {
  -ms-flex: 1 1 0px;
  -webkit-box-flex: 1;
  flex: 1;
  -ms-flex-preferred-size: 0px;
      flex-basis: 0px;
  width: 1%;
}
.hide-scrollbar::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.allcover {
  position: absolute;
  top: 0;
  right: 0;
}
.center {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.ct {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.cl {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.clear-fix {
  *zoom: 1;
}
.clear-fix:before,
.clear-fix:after {
  content: "";
  display: table;
  clear: both;
  overflow: hidden;
}
.yd-confirm-bd {
  margin-top: 0.2rem;
  height: 260px;
  overflow: auto;
}
.submit-order {
  padding-bottom: 1rem;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  background: #fbfbfb;
  height: 100%;
  /*优惠券列表*/
  /*  支付方式选择*/
}
.submit-order .fk-info-item {
  font-size: 0.32rem;
  color: #333;
  background: #fff;
  width: 100%;
  height: 1rem;
  padding: 0 0.24rem;
  margin-top: 0.2rem;
}
.submit-order .fk-info-item input::-webkit-input-placeholder {
  font-size: 0.32rem;
  color: #999;
}
.submit-order .fk-info-item input::-moz-placeholder {
  font-size: 0.32rem;
  color: #999;
}
.submit-order .fk-info-item input:-ms-input-placeholder {
  font-size: 0.32rem;
  color: #999;
}
.submit-order .fk-info-item input::-ms-input-placeholder {
  font-size: 0.32rem;
  color: #999;
}
.submit-order .fk-info-item input::placeholder {
  font-size: 0.32rem;
  color: #999;
}
.submit-order .pay-sty {
  width: 60%;
  height: 80%;
  position: relative;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
  border-radius: 1rem;
  line-height: 0.88rem;
  font-size: 0.32rem;
  color: #fff;
  text-align: center;
  border: 0;
}
.submit-order .pay-stys {
  width: 60%;
  margin: 0 20% 0.2rem;
  background: #409eff;
  border-radius: 1rem;
  line-height: 0.88rem;
  font-size: 0.32rem;
  color: #fff;
  text-align: center;
  border: 0;
}
.submit-order .order-top {
  width: 100%;
  height: 1rem;
  background: var(--main-color);
  padding: 0 0.3rem 0 0.24rem;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.submit-order .order-top .top-left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 0.3rem;
  color: #fff;
}
.submit-order .order-top .top-left img {
  width: 0.48rem;
  height: 0.48rem;
  margin-right: 6px;
}
.submit-order .order-top .top-right {
  color: #fff;
  font-size: 0.3rem;
}
.submit-order .order-address {
  min-height: 1.6rem;
  position: relative;
  padding: 0.36rem 1rem 0.36rem 0.3rem;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  line-height: 0.4rem;
  background: white url(../../assets/ic_jinru_right_black@3x.png) no-repeat 92% 50%;
  background-size: 0.36rem 0.36rem;
  /*无收货地址*/
}
.submit-order .order-address .user-info {
  font-size: 0.4rem;
  color: #333;
  margin-bottom: 3px;
}
.submit-order .order-address .user-info .user-name {
  margin-right: 0.26rem;
}
.submit-order .order-address .user-address {
  font-size: 0.22rem;
  color: #666666;
}
.submit-order .order-address .no-address {
  font-size: 0.4rem;
  color: #333;
  line-height: 0.88rem;
  font-weight: 500;
}
.submit-order .order-address .order-line {
  height: 3px;
  width: 100%;
  background: url("~@/assets/img_dingdan_tiaoxiushi@2x.png") no-repeat;
  background-size: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
}
.submit-order .fs-16 {
  font-size: 0.32rem;
  font-weight: bold;
}
.submit-order .fs-weight {
  font-weight: bold;
}
.submit-order .order-content {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  background: #fbfbfb;
  padding: 0.2rem 0.3rem 1rem;
  /*商品信息*/
  /*订单信息*/
}
.submit-order .order-content .goods-shop {
  padding: 0.2rem;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  font-size: 0.28rem;
  color: #333;
  position: relative;
}
.submit-order .order-content .goods-shop::before {
  content: "";
  width: 2px;
  height: 0.2rem;
  background: var(--main-color);
  position: absolute;
  top: 50%;
  margin-top: -0.1rem;
  left: 0;
}
.submit-order .order-content .pick-list {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin-bottom: 0.3rem;
  margin-top: 0.3rem;
}
.submit-order .order-content .pick-list .order-line {
  background-color: #ffffff;
  padding: 0 0.2rem;
  border-bottom: 1px solid #e1e1e1;
  height: 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  font-size: 0.28rem;
  color: #333;
}
.submit-order .order-content .pick-list .order-line .account_input {
  width: 5rem;
  border: 1px solid #e0e0e0;
  padding: 0.1rem 0.3rem 0.1rem 0;
  border-radius: 0.25rem;
  outline: none;
}
.submit-order .order-content .pick-list .order-line input {
  text-align: right;
  -webkit-appearance: none;
}
.submit-order .order-content .pick-list .order-line input::-webkit-input-placeholder {
  color: #999;
}
.submit-order .order-content .pick-list .order-line input::-moz-placeholder {
  color: #999;
}
.submit-order .order-content .pick-list .order-line input:-ms-input-placeholder {
  color: #999;
}
.submit-order .order-content .pick-list .order-line input::-ms-input-placeholder {
  color: #999;
}
.submit-order .order-content .pick-list .order-line input::placeholder {
  color: #999;
}
.submit-order .order-content .pick-list .b0 {
  border: none;
}
.submit-order .order-content .pick-list .mix-box {
  line-height: 0.4rem;
  padding-bottom: 0.2rem;
  font-size: 0.28rem;
  color: #333;
}
.submit-order .order-content .pick-list .mix-box span {
  font-size: 0.28rem;
  color: #fe552e;
}
.submit-order .order-content .pick-list .mix-box p {
  padding-right: 0.38rem;
}
.submit-order .order-content .order-info {
  padding: 0 0.2rem;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  background: #fff;
  margin-bottom: 0.3rem;
  margin-top: 0.3rem;
}
.submit-order .order-content .order-info .info-title {
  font-size: 0.3rem;
  color: #333;
  font-weight: 500;
  padding: 0.2rem 0;
  border-bottom: 1px solid #e1e1e1;
  margin-bottom: 0.2rem;
}
.submit-order .order-content .order-info .info-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  font-size: 0.28rem;
  color: #333;
  margin-bottom: 0.2rem;
}
.submit-order .order-content .order-info .info-all {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
  font-size: 0.28rem;
  color: #333;
  height: 1rem;
  border-top: 1px solid #e1e1e1;
}
.submit-order .order-content .order-info .info-all p {
  margin-left: 5px;
}
.submit-order .order-content .order-info .order-line {
  border-bottom: 1px solid #e1e1e1;
  height: 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  font-size: 0.28rem;
  color: #333;
}
.submit-order .order-content .order-info .order-line .account_input {
  width: 5rem;
  border: 1px solid #e0e0e0;
  padding: 0.1rem 0.3rem 0.1rem 0;
  border-radius: 0.25rem;
  outline: none;
}
.submit-order .order-content .order-info .order-line input {
  text-align: right;
  -webkit-appearance: none;
}
.submit-order .order-content .order-info .order-line input::-webkit-input-placeholder {
  color: #999;
}
.submit-order .order-content .order-info .order-line input::-moz-placeholder {
  color: #999;
}
.submit-order .order-content .order-info .order-line input:-ms-input-placeholder {
  color: #999;
}
.submit-order .order-content .order-info .order-line input::-ms-input-placeholder {
  color: #999;
}
.submit-order .order-content .order-info .order-line input::placeholder {
  color: #999;
}
.submit-order .order-content .order-info .b0 {
  border: none;
}
.submit-order .order-content .order-info .mix-box {
  line-height: 0.4rem;
  padding-bottom: 0.2rem;
  font-size: 0.28rem;
  color: #333;
}
.submit-order .order-content .order-info .mix-box span {
  font-size: 0.28rem;
  color: #fe552e;
}
.submit-order .order-content .order-info .mix-box p {
  padding-right: 0.38rem;
}
.submit-order .order-content .order-info .order-line:nth-last-child(1) {
  border: none;
}
.submit-order .order-content .order-info .prompt {
  font-size: 0.28rem;
  color: #999;
}
.submit-order .order-content .order-info .arrow {
  background: url("../../assets/ic_jinru_right_black@3x.png") no-repeat 100% 50%;
  background-size: 0.2rem 0.2rem;
}
.submit-order .order-content .order-info .pr36 {
  padding-right: 0.36rem;
}
.submit-order .order-content .order-info .arrow-grey {
  background: white url(../../assets/Rectangle@3x.png) no-repeat 100% 50%;
  background-size: 0.24rem 0.24rem;
  padding-right: 0.38rem;
}
.submit-order .order-content .order-info .budget-arrow-grey {
  background: white url(../../assets/Rectangle@3x.png) no-repeat 100% 50%;
  background-size: 0.24rem 0.24rem;
  padding-right: 0.38rem;
}
.submit-order .order-content .order-info select {
  margin-left: 0;
  font-size: 0.28rem;
  border: none;
  color: #999;
}
.submit-order .order-content .max-integral {
  text-align: right;
  padding-bottom: 0.1rem;
}
.submit-order .order-btn {
  width: 100%;
  height: 1rem;
  background: #fff;
  position: fixed;
  bottom: 0;
  padding: 0 0.3rem;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-box-shadow: 0 0.02rem 0.08rem 0 rgba(0, 0, 0, 0.1);
          box-shadow: 0 0.02rem 0.08rem 0 rgba(0, 0, 0, 0.1);
}
.submit-order .order-btn span {
  font-size: 0.36rem;
  color: #333;
  font-weight: bold;
}
.submit-order .order-btn .pay-btn {
  width: 2rem;
  height: 0.68rem;
  line-height: 0.68rem;
  text-align: center;
  background: var(--main-color);
  border-radius: 0.45rem;
  font-size: 0.28rem;
  color: #fff;
}
.submit-order .order-btn .ban-btn {
  background-color: #999999;
}
.submit-order .coupons-list {
  /*优惠券列表*/
  /*不使用按钮*/
}
.submit-order .coupons-list .yd-popup-show {
  border-radius: 0.2rem 0.2rem 0 0;
  overflow: hidden;
}
.submit-order .coupons-list .title-fix {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 5;
  background: #fff;
  border-radius: 0.2rem 0.2rem 0 0;
  /*tab切换*/
}
.submit-order .coupons-list .title-fix .title {
  height: 1.14rem;
  line-height: 1.14rem;
  font-size: 0.32rem;
  color: #333;
  text-align: center;
  position: relative;
}
.submit-order .coupons-list .title-fix .title img {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0.24rem;
  margin: auto;
  width: 0.24rem;
  height: 0.24rem;
}
.submit-order .coupons-list .title-fix .tab-box {
  height: 0.88rem;
  padding: 0 1.26rem 0 1.44rem;
  font-size: 0.28rem;
  color: #999;
}
.submit-order .coupons-list .title-fix .tab-box .tab-item {
  height: 100%;
  line-height: 0.88rem;
  position: relative;
}
.submit-order .coupons-list .title-fix .tab-box .select-item {
  font-size: 0.28rem;
  color: var(--main-color);
  font-weight: 500;
}
.submit-order .coupons-list .title-fix .tab-box .line {
  position: absolute;
  width: 0.5rem;
  height: 0.04rem;
  border-radius: 0.06rem;
  background: var(--main-color);
  bottom: 20%;
  left: 0;
  right: 0;
  margin: auto;
}
.submit-order .coupons-list .mb188 {
  margin-bottom: 1.88rem;
}
.submit-order .coupons-list .coupons-box {
  margin-top: 2.02rem;
  overflow: scroll;
  height: 6.58rem;
  -webkit-overflow-scroll: touch;
  position: absolute;
  left: 0;
  right: 0;
}
.submit-order .coupons-list .empty-couponsBox {
  margin-top: 1.3rem;
}
.submit-order .coupons-list .empty-box {
  margin-top: 2.02rem;
  height: 8.5rem;
  background: #f5f5f5;
}
.submit-order .coupons-list .empty-height {
  margin-top: 0;
  height: 10.5rem;
}
.submit-order .coupons-list .no-coupons {
  width: 100%;
  height: 1.38rem;
  padding: 0 0.24rem;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 3;
  background: #fff;
}
.submit-order .coupons-list .no-coupons .no-btn {
  height: 0.88rem;
  line-height: 0.88rem;
  text-align: center;
  background: #fff;
  border-radius: 0.44rem;
  border: 0.02rem solid var(--main-color);
  font-size: 0.32rem;
  color: var(--main-color);
}
.submit-order .list-confirm {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.submit-order .list-confirm .list-confirm-bk {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  -webkit-transform: -webkit-translate(-50%, -50%);
          transform: -webkit-translate(-50%, -50%);
  width: 80%;
  height: 68%;
  background: #fff;
  border-radius: 0.1rem;
  -webkit-border-radius: 0.1rem;
}
.submit-order .list-confirm .list-confirm-bk .list-confirm-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 0.7rem;
  font-size: 0.3rem;
  color: #fff;
  text-align: center;
  line-height: 0.7rem;
  position: absolute;
  bottom: 0;
  width: 100%;
}
.submit-order .list-confirm .list-confirm-bk .list-confirm-btn .list-confirm-btnleft {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  background: #cccccc;
  border-radius: 0 0 0 0.1rem;
  -webkit-border-radius: 0 0 0 0.1rem;
}
.submit-order .list-confirm .list-confirm-bk .list-confirm-btn .list-confirm-btnright {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  background: var(--main-color);
  border-radius: 0 0 0.1rem 0;
  -webkit-border-radius: 0 0 0.1rem 0;
}
.submit-order .list-confirm .list-confirm-bk h5 {
  height: 0.8rem;
  text-align: center;
  line-height: 0.8rem;
  border-bottom: 1px solid #dddddd;
}
.submit-order .list-confirm .list-confirm-bk .unavailable-items-box {
  height: 80%;
  overflow-y: auto;
}
.submit-order .list-confirm .list-confirm-bk .unavailable-items-box .unavailable-items {
  padding: 0.16rem 0.23rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.submit-order .list-confirm .list-confirm-bk .unavailable-items-box .unavailable-items .list-confirm-img {
  width: 1.41rem;
  height: 1.41rem;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.submit-order .list-confirm .list-confirm-bk .unavailable-items-box .unavailable-items .content-box {
  padding: 0.23rem 0 0.23rem 0.23rem;
  -webkit-box-flex: 3;
      -ms-flex: 3;
          flex: 3;
}
.submit-order .list-confirm .list-confirm-bk .unavailable-items-box .unavailable-items .content-box .content-list-name {
  font-size: 0.28rem;
  max-width: 65%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  word-wrap: break-word;
  float: left;
  line-height: 0.36rem;
}
.submit-order .list-confirm .list-confirm-bk .unavailable-items-box .unavailable-items .content-box .content-list-number {
  float: right;
  font-size: 0.28rem;
  padding-top: 0.23rem;
  text-align: right;
  color: #b3b3b3;
}
.submit-order .payment-box .yd-popup-show {
  border-radius: 0.2rem 0.2rem 0 0;
  overflow: hidden;
}
.submit-order .payment-box .pay-item {
  height: 1.18rem;
  line-height: 1.18rem;
  text-align: center;
  border-bottom: 0.02rem solid #ececec;
  font-size: 0.32rem;
  color: #333;
}
.submit-order .payment-box .pay-item:last-child {
  border: 0;
}
.submit-order .yd-keyboard-title {
  display: none;
}
.fs-weight {
  font-weight: bold;
}
.explode-text {
  text-align: right;
  line-height: 0.4rem;
  padding-bottom: 0.15rem;
}
.explode-text .explode-layout {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
.explode-text .explode-layout .text-layout {
  font-size: 0.28rem;
}
.explode-text .explode-layout .pay-type {
  margin-left: 0.25rem;
}
.explode-text .explode-layout .pay-type .yd-checkbox {
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.explode-text .explode-layout .pay-type .yd-checkbox .yd-checkbox-text {
  padding-left: 0.05rem;
}
.color-main {
  color: var(--main-color);
}
